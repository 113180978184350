<template>
  <div
    class="default-card-border card-level column default-rounded default-shadow"
    :style="{ backgroundColor: background }"
  >
    <div
      class="card-c-header full-width items-center row justify-between"
      :style="{ color: color }"
    >
      <p class="text-h5 q-pa-sm q-ma-sm ">{{ level }}</p>
      <p class="text-h5 q-pa-sm q-ma-sm row no-wrap items-center">
        <q-icon name="lock" />
        {{ point }}
      </p>
    </div>
    <div class="row q-pa-md content bg-white">
      <ul>
        <li
          class="row items-center no-wrap text-weight-medium"
          v-for="(item, index) in content"
          :key="index"
        >
          <span class="circle-point q-mr-md"></span>
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardLevel",

  props: {
    background: {
      default: "#000",
      type: String,
    },
    color: {
      default: "#fff",
      type: String,
    },
    level: {
      required: true,
      type: String,
    },
    point: {
      required: true,
      type: Number,
    },
    content: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style scoped lang="scss">
.card-level {
  min-width: 300px;
  .content {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
    flex: 1;
    ul {
      list-style: none;
      .circle-point {
        display: block;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #2aa7ff;
      }
    }
  }
}
</style>

<template>
  <div v-if="!in_request">
    <div
      class="default-card-border card-hierarchy custom-box-shadow bg-white default-rounded default-shadow"
    >
      <div class="row q-ml-md no-wrap q-pa-sm items-center">
        <div class="circle">
          <q-knob
            show-value
            readonly
            model-value=""
            :font-size="$q.platform.is.mobile ? '10px' : '16px'"
            class="text-dark q-ma-md"
            :size="$q.platform.is.mobile ? '50px' : '100px'"
            :value="(hierarchy.amount / hierarchy.max_level) * 100"
            :thickness="0.04"
            color="blue"
            track-color="blue-1"
          >
            {{ hierarchy.amount }}/{{ hierarchy.max_level }}
          </q-knob>
        </div>
        <div class="content q-ml-lg">
          <p class="text-h5 q-mb-sm text-weight-medium">
            {{ hierarchy.title }}
          </p>
          <p class="text-subtitle1 row items-center no-wrap">
            <span class="circle-point q-mr-md"></span>
            {{ hierarchy.message }}
          </p>
        </div>
      </div>
    </div>
    <p class="text-uppercase text-weight-bolder q-mt-lg q-mb-lg">
      Próximos níveis
    </p>
    <div
      class="row wrap"
      :class="{
        'justify-between': !$q.platform.is.mobile,
        'justify-center': $q.platform.is.mobile,
      }"
    >
      <card-level
        v-for="(info, index) in hierarchy.infos"
        class="q-ma-sm col-5"
        :key="'level' + index"
        :background="info.backcolor"
        :color="info.forecolor"
        :content="info.descriptions"
        :level="info.level"
        :point="1000"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import CardLevel from "./componets/cardLevel";
import { ref } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("hierarchy");

export default {
  components: { CardLevel },

  setup() {
    const in_request = ref(true),
      { fetchHierarchy } = useActions(["fetchHierarchy"]),
      { hierarchy } = useGetters({
        hierarchy: "getHierarchy",
      });

    fetchHierarchy().finally(() => {
      in_request.value = false;
    });

    return {
      hierarchy,
      in_request,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-hierarchy {
  .custom-box-shadow {
    box-shadow: -4px 0px 0px 0px #2aa7ff;
  }
  .circle-point {
    display: block;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #2aa7ff;
  }
}
.mobile {
  .card-hierarchy {
    .text-h5 {
      font-size: 1em;
      margin-bottom: 1px !important;
    }
    .text-subtitle1 {
      font-size: 0.8em;
      margin-bottom: 1px !important;
    }
    .circle-point {
      display: none;
    }
  }
}
</style>
